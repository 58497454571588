<template>
  <div>
    <!-- <img :src="(color && text) ? `${imageDomain}/assets/img/hot-offer-${color}.png`: `${imageDomain}/assets/img/hot-offer.png`"
    class="hot-offer-badge" width="100" height="100" alt="hot-offer"/>
    <p class="badge-text">{{ cutText }}</p> -->

    <img :src="`${imageDomain}/assets/img/hot-offer.png`" v-if="!color || !text"
      class="hot-offer-badge" width="100" height="100" alt="hot-offer"/>

  </div>
</template>
<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'hot-offer-icon',
  mixins: [imageUrlMixin],
  props: {
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
  .hot-offer-badge{
    transform: rotate(90deg);
    width: 100%;
    height: 100%;
  }
  .badge-text{
    position: absolute;
    top: calc(50% - 2.2rem);
    left: calc(50% - 0.8rem);
    transform: rotate(45deg);
    color: white;
    line-height: 1.2rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
</style>
